<template>
  <a-config-provider :locale="zh_CN">
    <!-- <AppHomePage /> -->
    <div class="view-centent">
      <router-view></router-view>
    </div>
  </a-config-provider>
</template>

<script>
import { ConfigProvider } from "ant-design-vue";
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import "./style.less";

export default {
  name: "App",
  data() {
    return {
      zh_CN,
    };
  },
  created() {},
  components: {
    AConfigProvider: ConfigProvider,
  },
};
</script>

<style>
</style>
