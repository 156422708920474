
import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress"
import "nprogress/nprogress.css"
import Vuex from './store'
import {
  getWaitList,
} from "@/utils/api";

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: "/",
      redirect: '/home',
    },
    {
      path: "/home",
      meta: {
        title: '透明管理'
      },
      component: () =>
        import(/* webpackChunkName: "home" */ '@/pages/home/index'),
    },
    {
      path: "/login",
      name: 'login',
      meta: {
        title: '登录'
      },
      component: () =>
        import(/* webpackChunkName: "login" */ '@/pages/login'),
    },
    {
      path: "/test",
      meta: {
        title: '测试页面'
      },
      component: () =>
        import(/* webpackChunkName: "test" */ '@/pages/test'),
    },
    {
      path: "/report",
      meta: {
        title: ''
      },
      component: () =>
        import(/* webpackChunkName: "report" */ '@/pages/report/index'),
    },
    {
      path: "/result",
      meta: {
        title: ''
      },
      component: () =>
        import(/* webpackChunkName: "result" */ '@/pages/report/result'),
    },
    {
      path: "/order",
      meta: {
        title: ''
      },
      component: () =>
        import(/* webpackChunkName: "order" */ '@/pages/order/index'),
    },
    {
      path: "/detail",
      meta: {
        title: ''
      },
      component: () =>
        import(/* webpackChunkName: "detail" */ '@/pages/order/detail'),
    },
    {
      path: "/enter",
      meta: {
        title: ''
      },
      component: () =>
        import(/* webpackChunkName: "enter" */ '@/pages/order/enter'),
    },
    {
      path: "/finish",
      meta: {
        title: ''
      },
      component: () =>
        import(/* webpackChunkName: "finish" */ '@/pages/order/finish'),
    },
    {
      path: "/my",
      meta: {
        title: '我的'
      },
      component: () =>
        import(/* webpackChunkName: "my" */ '@/pages/my/index'),
    },
    {
      path: "/my-detail",
      meta: {
        title: ''
      },
      component: () =>
        import(/* webpackChunkName: "myDetail" */ '@/pages/my/detail'),
    },
    {
      path: "/password",
      meta: {
        title: ''
      },
      component: () =>
        import(/* webpackChunkName: "password" */ '@/pages/my/password'),
    },
    {
      path: "/invite",
      meta: {
        title: ''
      },
      component: () =>
        import(/* webpackChunkName: "invite" */ '@/pages/my/invite'),
    },
    {
      path: "/feedback",
      meta: {
        title: ''
      },
      component: () =>
        import(/* webpackChunkName: "feedback" */ '@/pages/my/feedback'),
    },
    {
      path: "/message",
      meta: {
        title: '消息中心'
      },
      component: () =>
        import(/* webpackChunkName: "message" */ '@/pages/message/index'),
    },
    {
      path: "/case",
      meta: {
        title: '案例中心'
      },
      component: () =>
        import(/* webpackChunkName: "case" */ '@/pages/case/index'),
    },
    {
      path: "/case-detail",
      meta: {
        title: ''
      },
      component: () =>
        import(/* webpackChunkName: "caseDetail" */ '@/pages/case/detail'),
    },
    {
      path: "/register",
      meta: {
        title: '注册'
      },
      component: () =>
        import(/* webpackChunkName: "register" */ '@/pages/register'),
    },
    {
      path: "/reg-result",
      meta: {
        title: '注册'
      },
      component: () =>
        import(/* webpackChunkName: "regResult" */ '@/pages/result'),
    },
  ],
});

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
router.beforeEach((to, from, next) => {
  NProgress.start()
  if (to.meta.title) {
    document.title = to.meta.title
  }
  let accessToken = localStorage.getItem('accessToken') || '';
  if (!accessToken && to.path !== '/login' && to.path !== '/register' && to.path !== '/reg-result') {
    getWaitList()
    // next({ name: 'login' })
  } else {
    next()
  }

  // next()
})
router.afterEach((to, from) => {
  // if((from.path == '/withdrawal' || from.path == '/withdrawalSure') && to.path != '/withdrawalSure' && to.path != '/editMobile') {
  //   Vuex.commit('withdrawal/resetState')
  // }
  // if((from.path == '/payment' || from.path == '/paymentSure') && to.path != '/paymentSure' && to.path != "/payment") {
  //   Vuex.commit('payment/initData')
  // }
  Vuex.commit('global/setRoute', router.app._route)
  NProgress.done()
})
export default router