import request from './request'

export function login(params = {}) {//登录
    return request({
        url: "/fe/user/login",
        method: "POST",
        data: params
    })
}
export function getOrderList(params = {}) { //获取工单分类列表
    return request({
        url: "/fe/fe_order_classfication/list",
        method: "GET",
        data: params
    })
}
export function getDepartList(params = {}) { //获取部门列表
    return request({
        url: "/fe/fe_order_dept_classfication/list",
        method: "GET",
        data: params
    })
}
export function getNatureList(params = {}) { //获取性质分类列表
    return request({
        url: "/fe/fe_matter_property/list",
        method: "GET",
        data: params
    })
}
export function getProblemList(params = {}) { //获取问题分类列表
    return request({
        url: "/fe/fe_matter_classfication/list",
        method: "GET",
        data: params
    })
}
export function getAreList(params = {}) { //获取区域列表
    return request({
        url: "/fe/fe_area_location/list",
        method: "GET",
        data: params
    })
}
// export function getPendingList(params = {}) { //获取待处理列表
//     return request({
//         url: "/fe/fe_area_location/list",
//         method: "GET",
//         data: params
//     })
// }
export function getNewsList(params = {}) { //获取最新动态列表
    return request({
        url: "/fe/fe_area_location/list",
        method: "GET",
        data: params
    })
}
export function getBestList(params = {}) { //获取最新实践列表
    return request({
        url: "/fe/fe_area_location/list",
        method: "GET",
        data: params
    })
}

export function saveOrder(params = {}) {//保存工单
    return request({
        url: "/fe/fe_order/createOrder",
        method: "POST",
        data: params
    })
}
export function assignOrder(params = {}) {//交办工单
    return request({
        url: "/fe/fe_order/assignOrder",
        method: "POST",
        data: params
    })
}
export function handleSuccess(params = {}) {//处理完成
    return request({
        url: "/fe/fe_order/handleSuccess",
        method: "POST",
        data: params
    })
}
export function acceptSuccess(params = {}) {//验收工单
    return request({
        url: "/fe/fe_order/acceptSuccess",
        method: "POST",
        data: params
    })
}
export function submitOrder(params = {}) {//提交工单
    return request({
        url: "/fe/fe_order/submitOrder",
        method: "POST",
        data: params
    })
}
export function saveEditOrder(params = {}) {//修改保存工单
    return request({
        url: "/fe/fe_order/update",
        method: "POST",
        data: params
    })
}
export function enterHandle(params = {}) {//录入处理
    return request({
        url: "/fe/fe_receipt",
        method: "POST",
        data: params
    })
}
export function backspace(params = {}) {//回退
    return request({
        url: "/fe/fe_order/backspace",
        method: "POST",
        data: params
    })
}
export function evaluate(params = {}) {//评分
    return request({
        url: "/fe/fe_order_evaluate",
        method: "POST",
        data: params
    })
}
export function withdrawOrder(params = {}) {//撤回
    return request({
        url: "/fe/fe_order/withdrawOrder",
        method: "POST",
        data: params
    })
}
export function changePassword(params = {}) {//修改密码
    return request({
        url: "/fe/user/changePassword",
        method: "POST",
        data: params
    })
}
export function addEnterprise(params = {}) {//新增意向企业
    return request({
        url: "/fe/fe_intention_enterprise",
        method: "POST",
        data: params
    })
}
export function recommendation(params = {}) {//意见反馈
    return request({
        url: "/fe/fe_platform_recommendation",
        method: "POST",
        data: params
    })
}
export function handleFailure(params = {}) {//暂不受理
    return request({
        url: "/fe/fe_order/handleFailure",
        method: "POST",
        data: params
    })
}
export function saveDraft(params = {}) {//重新提交【编辑保存】
    return request({
        url: "/fe/fe_order/saveDraft",
        method: "POST",
        data: params
    })
}
export function getUserList(params = {}) {//查询交办人列表
    return request({
        url: "/fe/fe_user_orders/getUsers",
        method: "GET",
        params
    })
}
export function getSubList(params = {}) {//查询我提交的列表
    return request({
        url: "/fe/fe_order/listPagesMySubmit",
        method: "GET",
        params
    })
}
export function getHandleList(params = {}) {//查询已处理的列表
    return request({
        url: "/fe/fe_order/listPagesHandled",
        method: "GET",
        params
    })
}
export function getWaitList(params = {}) {//查询待处理的列表
    return request({
        url: "/fe/fe_order/listPagesForHandle",
        method: "GET",
        params
    })
}
export function getDraftList(params = {}) {//查询待提交的列表
    return request({
        url: "/fe/fe_order/listPagesForSubmit",
        method: "GET",
        params
    })
}
export function getDetail(params) {//查询详情
    return request({
        url: `/fe/fe_order/${params}`,
        method: "GET"
    })
}
export function getUserDetail(params) {//查询用户详情
    return request({
        url: `/fe/fe_user_orders/findDetailById/${params}`,
        method: "GET"
    })
}
export function getOrderItemsNumber(params) {//查询数量
    return request({
        url: '/fe/fe_order/getOrderItemsNumber',
        method: "GET"
    })
}
export function wxLogin(params) {//login
    return request({
        url: '/fe/wx/wxLogin',
        method: "GET"
    })
}

export function getUserInfo(params) {//获取个人信息
    return request({
        url: '/fe/fe_user_orders/getCurrentUserInfo',
        method: "GET"
    })
}
export function getMessges(params) {//消息列表
    return request({
        url: '/fe/mess_fe_wechat/listPages',
        method: "GET",
        params
    })
}

export function getBestPracticeOrders(params) {//最佳实践
    return request({
        url: '/fe/fe_order/getBestPracticeOrders',
        method: "GET",
        params
    })
}
export function listPagesAllCase(params) {//企业案例
    return request({
        url: '/fe/fe_order/listPagesAllCase',
        method: "GET",
        params
    })
}
export function listPagesMonthAdditional(params) {//本月新增
    return request({
        url: '/fe/fe_order/listPagesMonthAdditional',
        method: "GET",
        params
    })
}
export function listPagesRelatedMe(params) {//与我相关
    return request({
        url: '/fe/fe_order/listPagesRelatedMe',
        method: "GET",
        params
    })
}
export function listPagesPractice(params) {//最佳实践
    return request({
        url: '/fe/fe_order/listPagesPractice',
        method: "GET",
        params
    })
}
export function getCaseNumber(params) {//获取案例数量
    return request({
        url: '/fe/fe_order/getCaseNumber',
        method: "GET",
        params
    })
}



