import { myUser, queryCountAnnualFreeCard, frontAuthenticationInfo, loginOut, updateUserDisplay, queryAmSaleInfo, updateUserSign, queryMenu } from '@/utils/api'
import Vue from 'vue'
export default {
    namespaced: true,
    state: () => ({
        info: {},
        freeCard: "",
        authentication: {},
        realNameModal: false,
        entPerModal: false,
        amSaleInfo: {},
        routePath: "",
        menu: [],
        isShowAnnouncement: false,
        isShowHelp: false,
        isShowAccount: false,
        accountMenu: [],
    }),
    mutations: {
        setInfo(state, payload) {
            state.info = payload ? payload : {}
        },
        showRealModal(state, payload) {
            Vue.set(state, "realNameModal", payload)
        },
        showEntPerModal(state, payload) {
            Vue.set(state, "entPerModal", payload)
        },
        setamSaleInfo(state, payload) {
            Vue.set(state, "amSaleInfo", payload)
        },
        setRoute(state, payload) {
            Vue.set(state, "routePath", payload.path)
        },
        setqueryMenu(state, payload) {
            console.log('---', payload)
            state.isShowAnnouncement = payload.some(item => item.menuUrl == "announcement")
            state.isShowHelp = payload.some(item => item.menuUrl == "help")
            state.isShowAccount = payload.some(item => item.menuUrl == "accountCenter")
            let accountCenter = payload.find(item => item.menuUrl == "accountCenter")
            state.accountMenu = accountCenter && accountCenter.subMenus
            Vue.set(state, "menu", payload)
        },
    },
    actions: {
        // async frontAuthenticationInfoFn({ commit, state }) {
        //     const data = await frontAuthenticationInfo()
        //     if (data.success) {
        //         state.authentication = data.data
        //     }
        // },

        // async onloginOut({ commit, state }) {
        //     const data = await loginOut()
        //     if (data.success) {
        //         window.location.href = data.data.casLogoutUrl;
        //     }
        // },
        // async hideNewNav({ state, dispatch }) {
        //     const data = await updateUserDisplay({ display: "1" })
        //     if (data.success) {
        //         dispatch('getInfo')
        //     }
        // },
        // async amSaleInfo({ state, commit }) {
        //     const data = await queryAmSaleInfo()
        //     if (data.success) {
        //         commit("setamSaleInfo", data.data)
        //     }
        // },
        // async updateSign({ state, dispatch }) {
        //     const data = await updateUserSign({ sign: "1" })
        //     if (data.success) {
        //         dispatch('getInfo')
        //     }
        // },
    }
}