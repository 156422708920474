/**
 * 请求头
 */
// import qs from 'qs'
import axios from "axios"
import { Message } from 'ant-design-vue';
import { login } from './api'
let getOpenIdUrl = process.env.VUE_APP_API_OPEN_URL

axios.defaults.timeout = 50000;
axios.defaults.baseURL = process.env.VUE_APP_URL //填写域名
axios.defaults.withCredentials = true
// axios.defaults.headers = { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' };
// http request拦截器 添加一个请求拦截器
axios.interceptors.request.use(function (config) {
  let accessToken = localStorage.getItem('accessToken') || ''
  //将token放到请求头发送给服务器,将tokenkey放在请求头中
  config.headers['X-Requested-With'] = 'XMLHttpRequest';
  config.headers['accessToken'] = accessToken;
  config.headers['requestType'] = '1';
  // if (config.method === 'post') {
  //   if (config.headers['Content-Type'] === 'application/json') {
  //     // config.data = qs.stringify(config.data);
  //   }

  //   // config.data = qs.stringify(config.data);
  // }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});
//响应拦截器即异常处理
axios.interceptors.response.use(response => {
  return response
}, err => {
  const { redirect, contentpath } = err.response.headers
  if (err && err.response) {
    switch (err.response.status) {
      case 302:
        if (redirect == 'REDIRECT') {
          let nextPage = document.createElement('a');
          nextPage.setAttribute('href', contentpath);
          nextPage.click();
          console.log(window.location.href)
          localStorage.setItem('origintpath', window.location.href)
          // window.location.href = contentpath + '&&time=' + ((new Date()).getTime())
        }
        break;
      case 400:
        console.log('错误请求')
        break;
      case 401:
        console.log('未授权，请重新登录')
        break;
      case 403:
        console.log('拒绝访问')
        break;
      case 404:
        console.log('请求错误,未找到该资源')
        break;
      case 405:
        console.log('请求方法未允许')
        break;
      case 408:
        console.log('请求超时')
        break;
      case 500:
        Message.error('服务器端出错')
        console.log('服务器端出错')
        break;
      case 501:
        console.log('网络未实现')
        break;
      case 502:
        console.log('网络错误')
        break;
      case 503:
        console.log('服务不可用')
        break;
      case 504:
        console.log('网络超时')
        break;
      case 505:
        console.log('http版本不支持该请求')
        break;
      default:
        console.log(`连接错误${err.response.status}`)
    }
  } else {
    console.log('连接到服务器失败')
  }
  return Promise.resolve(err.response)
})
//获取openId
function getOpenId() {
  axios.get(getOpenIdUrl).then((res) => {
    console.log(res);
  });
}
async function loginFn() {
  let params = JSON.parse(localStorage.getItem("loginParams"));
  if (params) {
    const { resp_code, data } = await login(params);
    if (resp_code == 0) {
      let accessToken = data.accessToken;
      localStorage.setItem("accessToken", accessToken);
      location.reload()
    } else {
      window.location.href = data
      localStorage.removeItem("accessToken");
      localStorage.removeItem("loginParams");
    }
  }
}
/**
 * 封装方法
 * @param url
 * @param data
 * @returns {Promise}
 */
function request(options = {}, isWarn) {
  return new Promise((resolve, reject) => {
    axios(options)
      .then(response => {
        if (response) {
          resolve(response.data);
          if (!response.data.success) {
            // if( 
            //   process.env.NODE_ENV =="production" && 
            //   response.status == 200){
            //     location.href= response.request.responseURL
            //   // console.log('----',)
            // }else{
            //   console.log(isWarn)
            //   // if(!isWarn) {
            //   //   Modal.warning({
            //   //     title: '系统错误',
            //   //     content: response.data.message,
            //   //   })
            //   // }
            // }
          }
          if (response.status == 305) {
            getOpenId();
            loginFn();
          }
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}
export default request